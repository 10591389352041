export const initForms = () => {
    const iframes = document.querySelectorAll(".block-embed iframe");

    // Find &amp;amp;#x26; save the aspect ratio for all forms
    iframes.forEach((e) => {
        const height = e.getAttribute("height");
        const width = e.getAttribute("width");
        e.setAttribute("data-ratio", height / width);

        // Remove the hardcoded width &amp;amp;#x26; height attributes
        e.setAttribute("width", width);
        e.setAttribute("height", height);
        
    });
};

export const resizeForms = () => {
    const iframes = document.querySelectorAll(".block-embed iframe");

    // Find all videos
    iframes.forEach((e) => {
        // Get the parent container&amp;amp;#x27;s width
        const newWidth = e.width;
        const newHeight = e.height;
        e.setAttribute("width", newWidth);
        e.setAttribute("height", newHeight);
        e.setAttribute(
            "style",
            `width: ${newWidth}px; height: ${newHeight}px`
        );
    });
};
